import React, { useEffect } from 'react'
import { Text } from 'rebass/styled-components'
import { Container } from '../../components/base'
import { withLayout } from '../../components/Layout'

const OidcCallbackPage = () => {
  useEffect(() => {
    // @ts-ignore
    window.location = 'choicetopay://oidc-callback'
  }, [])

  return (
    <Container
      justifyContent="center"
      alignItems="center"
      css={{ minHeight: '700px' }}
    >
      <Text as="h1">Authenticating...</Text>
      <Text as="p">Redirecting back to mobile app.</Text>
    </Container>
  )
}

export default withLayout(OidcCallbackPage)
